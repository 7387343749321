import { Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IClass } from './interfaces';
import { Dropdown, Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import { useClassCallAPI } from './Hooks/useClassCallAPI';
import { useClassMemorizations } from './Hooks/useClassMemorizations';
import { useClassFunctions } from './Hooks/useClassFunctions';

export const Class: FC<IClass> = ({ product, disabledForm }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { listClassTypes, classTypesLoading } = useClassCallAPI();

  const { items } = useClassMemorizations({ listClassTypes });

  const { onChange } = useClassFunctions({ listClassTypes });

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('products.toolTips.products.class')} ${
          product?.classeProduto.abreviacao
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.classeProdutoId !== form?.values?.classeProdutoId
        }
      >
        <Dropdown
          items={items}
          name="classeProdutoId"
          label={[
            {
              children: t('products.create.class'),
            },
          ]}
          placeHolder={t('products.create.classPlaceholder')}
          disabled={disabledForm}
          withStatusIcon={!!product}
          isLoading={classTypesLoading}
          onChange={onChange}
          required
        />
      </Tooltip>
    </Col>
  );
};
