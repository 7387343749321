import { useCallback } from 'react';
import { setInitialValuesFormWaterConcentration } from '../../../../../diluitionsHelpers';
import { useFormikContext } from 'formik';

export const useWaterConcentrationFactorFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (stringValue: string) => {
    const numberValue = stringValue ? +stringValue.replace(',', '.') : 0;

    setInitialValuesFormWaterConcentration(form, numberValue);
  };

  return { onChange };
};
