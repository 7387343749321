import { setInitialValuesFormWaterConcentrationFactor } from '../../../../../diluitionsHelpers';
import { useFormikContext } from 'formik';

export const useWaterConcentrationFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (percentageValue: string) => {
    const numberValue = percentageValue
      ? +percentageValue.replace('%', '').replace(',', '.')
      : 0;
    setInitialValuesFormWaterConcentrationFactor(form, numberValue);
  };

  return { onChange };
};
