import { FormikContextType } from 'formik';
import { ICreateLotRequest } from '../../../../../../../Data/Interfaces/request/Lot/ILotResquest';

export const setInitialValuesFormWaterConcentrationFactor = (
  form: FormikContextType<ICreateLotRequest>,
  numberValue: number
) => {
  const currentFormWaterConcentrationFactor = (
    100 /
    (100 - numberValue)
  ).toFixed(3);

  if (!numberValue) {
    return form.setFieldValue(
      'loteInformacaoTecnica.fatorConcentracaoAgua',
      ''
    );
  }

  form.setFieldValue(
    'loteInformacaoTecnica.fatorConcentracaoAgua',
    currentFormWaterConcentrationFactor ||
      form?.values?.loteInformacaoTecnica?.fatorConcentracaoAgua
  );
};

export const setInitialValuesFormWaterConcentration = (
  form: FormikContextType<ICreateLotRequest>,
  numberValue: number
) => {
  if (!numberValue) {
    return form.setFieldValue('loteInformacaoTecnica.concentracaoAgua', '');
  }

  const currentFormWaterConcentration = 100 - 100 / numberValue;

  form.setFieldValue(
    'loteInformacaoTecnica.concentracaoAgua',
    currentFormWaterConcentration
  );
};

export const setInitialValuesFormInternalDiluitionFactor = (
  form: FormikContextType<ICreateLotRequest>
) => {
  const currentFormInternalDiluition =
    form?.values?.loteInformacaoTecnica?.diluicaoInterna;
  const currentFormInternalDiluitionFactor = 100 / currentFormInternalDiluition;
  const hasChanges =
    currentFormInternalDiluitionFactor !== currentFormInternalDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.fatorDiluicaoInterna',
      currentFormInternalDiluitionFactor ||
        form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna
    );
  }
};

export const setInitialValuesFormInternalDiluition = (
  form: FormikContextType<ICreateLotRequest>
) => {
  const currentFormInternalDiluitionFactor =
    form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna;
  const currentFormInternalDiluition = 100 / currentFormInternalDiluitionFactor;
  const hasChanges =
    currentFormInternalDiluitionFactor !== currentFormInternalDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.diluicaoInterna',
      currentFormInternalDiluition ||
        form?.values?.loteInformacaoTecnica?.diluicaoInterna
    );
  }
};
