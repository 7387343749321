import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { IUsecallAPI } from './interfaces';
import { isNil } from 'lodash';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { useEffect } from 'react';

export const useInitialQuantityCallAPI = ({
  typeClassProduct,
}: IUsecallAPI) => {
  const { data: listunitsMeasurements, refetch } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
      initialPagination: {
        pageIndex: 0,
        pageSize: 10,
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: typeClassProduct,
          },
        ],
      },
      autoIncrement: true,
      enabled: !isNil(typeClassProduct),
    });

  useEffect(() => {
    if (!isNil(typeClassProduct)) {
      refetch({
        pageIndex: 0,
        pageSize: 10,
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: typeClassProduct,
          },
        ],
      });
    }
  }, [typeClassProduct]);
  return {
    listunitsMeasurements,
  };
};
