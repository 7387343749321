import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Card,
  Dropdown,
  Form,
  TextInput,
} from '../../../../../../../../Components';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { useEditMemorizations } from '../../../Hooks/useEditMemorizations';

export const InfoBalanceAdjustmentCard: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { preferences, balanceAdjustment, loadingBalance } = useEditCallAPI({
    externalId,
  });
  const { initialValues, itemsResponsible } = useEditMemorizations({
    balanceAdjustment,
    preferences,
    loadingBalance,
  });
  const { t } = useTranslation();

  return (
    <Form initialValues={initialValues}>
      <Card
        title={t('supply.transfers.detailsForm.titleInformation')}
        withoutCollapse={true}
      >
        <Row gutter={[16, 0]}>
          <Col span={3}>
            <TextInput
              name="dataAjuste"
              label={t('supply.balanceAdjustment.details.adjustmentDate')}
              disabled={true}
            />
          </Col>
          <Col span={6}>
            <Dropdown
              name="responsavel"
              items={itemsResponsible}
              label={t('supply.balanceAdjustment.details.responsible')}
              disabled={true}
              withoutMarginBottom
              selectedItemFromForm="idResponsavel"
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <TextInput
              name="produtoDescricao"
              label={t('supply.balanceAdjustment.details.product')}
              disabled={true}
            />
          </Col>

          <Col span={12}>
            <TextInput
              name="descricaoLocalEstoque"
              label={t('supply.balanceAdjustment.details.stockLocation')}
              disabled={true}
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={11}>
            <TextInput
              name="numeroLote"
              label={t('supply.balanceAdjustment.details.lot')}
              disabled={true}
            />
          </Col>
          <Col span={6}>
            <TextInput
              name="nomeFornecedor"
              label={t('supply.balanceAdjustment.details.provider')}
              disabled={true}
            />
          </Col>
          <Col span={4}>
            <TextInput
              name="numeroNotaFiscal"
              label={t('supply.balanceAdjustment.details.identifier')}
              disabled={true}
            />
          </Col>
          <Col span={3}>
            <TextInput
              name="serieNotaFiscal"
              label={t('supply.balanceAdjustment.details.nfSeries')}
              disabled={true}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
