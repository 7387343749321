import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  InputWithSearch,
  TextInput,
} from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '../../../../../../../../../Components/Inputs/TextInputWithMask';
import { listProviderConfig } from '../../../../../Create/utils/functions';
import {
  FETCH_LIST_DROPDOWN_SUPPLIER,
  GET_DOCUMENT_PROVIDER,
} from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { providerListPagination } from '../../../../../Create/utils/constants';
import { FETCH_GET_OPEN_ORDER_PURCHASE } from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { IPagination } from '../../../../../../../../../Components/Table';
import { Tooltip } from '../../../../../../../../../Components';
import AddPurchaseOrderModal from '../../../../components/AddPurchaseOrderModal';
import { PurchaseOrdersModal } from '../../../../../Create/components/PurchaseOrdersModal';
import { RemovePurchaseOrdersModal } from '../../../../components/RemovePurchaseOrdersModal';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import { IGetDocumentProviderResponse } from '@/Data/Interfaces/response/Provider/IProviderResponse';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

interface IThirdRowProps {
  initialValues: any;
  setPurchaseOrderSelected: any;
  setModalTablePurchaseOrder: any;
  modalTablePurchaseOrderVisible: boolean;
  purchaseOrderSelected: any;
}

const ThirdRow: React.FC<IThirdRowProps> = ({
  initialValues,
  setPurchaseOrderSelected,
  setModalTablePurchaseOrder,
  modalTablePurchaseOrderVisible,
  purchaseOrderSelected,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const queryClient = useQueryClient();
  const [externalIdProvider, setExternalIdProvider] = useState<string>('');
  const [lastProviderId, setLastProviderId] = useState<string | null>(null);
  const [modalFoundVisible, setModalFoundVisible] = useState<boolean>(false);
  const [
    removePurchaseOrdersModalVisible,
    setRemovePurchaseOrdersModalVisible,
  ] = useState<boolean>(false);
  const [providerDescription, setProviderDescription] = useState<string>('');
  const [customFilter, setCustomFilter] = useState<any>([
    {
      filterName: 'fornecedorExternalId',
      value: externalIdProvider,
    },
  ]);

  const { data: documentProvider } =
    useControllerQueryApiHook<IGetDocumentProviderResponse>({
      uniqId: GET_DOCUMENT_PROVIDER,
      entityApi: SupplierAPI.getDocumentProvider,
      externalId: externalIdProvider,
    });

  const [pagination, setPagination] = useState<IPagination>({
    pageIndex: 0,
    pageSize: 10,
    sorter: { column: 'sequenciaGroupTenant', direction: 'DESC' },
  });

  const {
    data: purchaseOrderList,
    isLoading: purchaseOrderListLoading,
    refetch: refetchPurchaseOrderList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_OPEN_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOpenPurchaseOrderList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 7,
      sorter: { column: 'sequenciaGroupTenant', direction: 'DESC' },
    },
    customFilter: customFilter,
  });

  const {
    data: listProvider,
    isLoading: isLoadingProvider,
    fetchNewPage: fetchNewProviderPage,
    refetch: refetchSearchProviderPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_SUPPLIER,
    entityApi: SupplierAPI.listDropdownSupplier,
    initialPagination: providerListPagination,
    autoIncrement: true,
  });

  const invalidatePurchaseOrderListQuery = () =>
    queryClient.invalidateQueries([FETCH_GET_OPEN_ORDER_PURCHASE]);

  const setPurchaseOrderList = () => {
    if (purchaseOrderList?.data.length === 1) {
      setPurchaseOrderSelected(purchaseOrderList.data);
    } else {
      setModalTablePurchaseOrder(true);
    }
  };

  const checkInitialValues = useCallback(
    (key: string) => {
      if (form.values) {
        return form.values[key] !== initialValues[key];
      }
      return false;
    },
    [form.values]
  );

  useEffect(() => {
    if (form && documentProvider) {
      setCustomFilter([
        { filterName: 'fornecedorExternalId', value: externalIdProvider },
      ]);
      let newListProvider: any = listProvider;
      newListProvider = newListProvider.data
        ? newListProvider.data
        : newListProvider;

      const currentProviderDescription = newListProvider.filter(
        (e: any) => e.externalId === externalIdProvider
      )[0].nome;
      setProviderDescription(currentProviderDescription);
      form.setFieldValue('razaoSocial', documentProvider.razaoSocial);
      form.setFieldValue('cnpj', documentProvider.cnpj);
    }
  }, [externalIdProvider, documentProvider]);

  useEffect(() => {
    if (
      purchaseOrderList.data &&
      purchaseOrderList?.data.length &&
      externalIdProvider !== lastProviderId
    ) {
      setLastProviderId(externalIdProvider);
      setModalFoundVisible(true);
    }
  }, [purchaseOrderList]);

  return (
    <Row gutter={[16, 0]}>
      <Col span={8}>
        <Tooltip
          showMe={checkInitialValues('fornecedorExternalId')}
          title={`${t('purchasing.invoice.edit.originalProvider')}${
            initialValues.fornecedorDescricao
          }`}
          targetOffset={[150, 0]}
        >
          <InputWithSearch
            name="fornecedorExternalId"
            withoutMarginBottom
            placeHolder={t('purchasing.invoice.create.placeHolder.provider')}
            label={t('purchasing.invoice.create.provider')}
            items={listProviderConfig(listProvider)}
            isLoading={isLoadingProvider}
            onScrollEnd={fetchNewProviderPage}
            onSearch={(search?: string) => {
              refetchSearchProviderPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              });
            }}
            onChange={(value) => {
              const values = form.values;
              if (values.pedidosExternalId.length) {
                setRemovePurchaseOrdersModalVisible(true);
              } else {
                invalidatePurchaseOrderListQuery();
                setExternalIdProvider(value);
              }
            }}
            withStatusIcon
            required
          />
        </Tooltip>
      </Col>
      <Col span={8}>
        <TextInput
          name="razaoSocial"
          label={t('purchasing.invoice.create.socialReason')}
          disabled
        />
      </Col>
      <Col span={7}>
        <TextInputWithMask
          name="cnpj"
          mask="cnpjMask"
          label={t('purchasing.invoice.create.cnpj')}
          withoutMarginBottom={false}
          disabled
        />
      </Col>
      <AddPurchaseOrderModal
        visible={modalFoundVisible}
        changeVisibleModal={setModalFoundVisible}
        purchaseOrderList={purchaseOrderList?.data ?? []}
        totalPurchaseOrder={purchaseOrderList?.count ?? 0}
        setPurchaseOrderList={setPurchaseOrderList}
      />
      <PurchaseOrdersModal
        visible={modalTablePurchaseOrderVisible}
        purchaseOrderList={purchaseOrderList}
        changeVisibleModal={setModalTablePurchaseOrder}
        setPurchaseOrderList={setPurchaseOrderSelected}
        refetchPurchaseOrderList={refetchPurchaseOrderList}
        isLoading={purchaseOrderListLoading}
        purchaseOrderSelected={purchaseOrderSelected}
      />
      <RemovePurchaseOrdersModal
        visible={removePurchaseOrdersModalVisible}
        changeVisibleModal={(state: boolean) =>
          setRemovePurchaseOrdersModalVisible(state)
        }
        providerDescription={
          providerDescription
            ? providerDescription
            : form.initialValues.fornecedorDescricao
        }
        onOkClick={() => {
          form.setFieldValue('pedidosExternalId', []);
          setExternalIdProvider(form.values.fornecedorExternalId);
          invalidatePurchaseOrderListQuery();
          setPurchaseOrderSelected([]);
        }}
        onCancelClick={() => {
          form.values.fornecedorExternalId = externalIdProvider
            ? externalIdProvider
            : form.initialValues.fornecedorExternalId;
          setRemovePurchaseOrdersModalVisible(false);
        }}
      />
    </Row>
  );
};

export default ThirdRow;
